import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/model/indexBG.glb");
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    animations.forEach((e) => {
      actions[e.name].play();
    });
  });
  return (
    <group ref={group} {...props} dispose={null} rotation={[-Math.PI / 2, 0, -Math.PI / 3]}>
      <group name="Scene">
        <mesh
          name="Plane"
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials["Material.005"]}
          morphTargetDictionary={nodes.Plane.morphTargetDictionary}
          morphTargetInfluences={nodes.Plane.morphTargetInfluences}
          scale={3.83}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/model/indexBG.glb");
